/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {YouTube, Gallery, GalleryImage, gallerySettings, L} from "@src/style/mdxStyle.js";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    ul: "ul",
    li: "li",
    h2: "h2",
    h3: "h3"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "best-landscape-photography-processing-tutorials",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#best-landscape-photography-processing-tutorials",
    "aria-label": "best landscape photography processing tutorials permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Best landscape photography processing tutorials"), "\n", React.createElement(_components.p, null, "Post-processing landscapes is a vast topic."), "\n", React.createElement(_components.p, null, "There are so many tools one can use - Adobe Camera RAW and Lightroom/Bridge, Photoshop, On1, Luminar and so on.\nAnd within the tools themselves, there are many different ways to achieve the same look and feel."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "How to structure images catalogue in Lightroom or Bridge?"), "\n", React.createElement(_components.li, null, "How to approach RAW processing? There are so many sliders, how do they work?"), "\n", React.createElement(_components.li, null, "What are all those instruments and filters in Adobe Photoshop?"), "\n", React.createElement(_components.li, null, "And, of course, how to get to that feel, how not to make rookie mistakes?"), "\n"), "\n", React.createElement(_components.p, null, "Tones of questions and so overwhelming, especially when you are just starting - we've all been there."), "\n", React.createElement(_components.p, null, "This is why, I believe, having a good teacher is very important."), "\n", React.createElement(_components.p, null, "Years ago, there were books and not so many video lessons. But times have changed, and in this article I want to assemble a list of good time-tested video lessons, by people who know what they are doing."), "\n", React.createElement(_components.h2, {
    id: "full-courses",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#full-courses",
    "aria-label": "full courses permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Full courses"), "\n", React.createElement(_components.p, null, "Here you will find full courses, usually explaining post-processing from start to finish."), "\n", React.createElement(_components.p, null, "Most of the people in this list focus on Adobe Photoshop, it being the most advanced tool for images post-processing. Courses would include RAW pre-processing, detailed workflow in Adobe Photoshop, use of the extra filters and tools, luminosity masking, sharpening for web and many more."), "\n", React.createElement(_components.p, null, "If you find that this list is not full and you or someone you know should be added – send me a message."), "\n", React.createElement(_components.h3, {
    id: "alex-noriega",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#alex-noriega",
    "aria-label": "alex noriega permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Alex Noriega"), "\n", React.createElement(_components.p, null, "Alex started out with photography more than 10 years ago, initially shooting mostly epic landscapes."), "\n", React.createElement(_components.p, null, "Over time, his focus shifted more to small and intimate scenes, while post-processing level stayed high, letting him create beautiful and eye-catchy images even without epic sunsets."), "\n", React.createElement(_components.p, null, "Here is a small gallery of his images, you can find much more on his ", React.createElement(L, {
    t: "website",
    h: "https://www.alexnoriega.com/"
  }), "."), "\n", React.createElement(Gallery, gallerySettings, React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://images.squarespace-cdn.com/content/v1/5755d322b654f9b7a7138680/1553720972669-AQXN69PM9LEYH9P70NOU/ke17ZwdGBToddI8pDm48kB6N0s8PWtX2k_eW8krg04V7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QPOohDIaIeljMHgDF5CVlOqpeNLcJ80NK65_fV7S1URWK2DJDpV27WG7FD5VZsfFVodF6E_6KI51EW1dNf095hdyjf10zfCEVHp52s13p8g/rainbow-rider.jpg?format=1000w"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://images.squarespace-cdn.com/content/v1/5755d322b654f9b7a7138680/1554239351706-29THOSHBG5CZKIGT6MB9/ke17ZwdGBToddI8pDm48kB6N0s8PWtX2k_eW8krg04V7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QPOohDIaIeljMHgDF5CVlOqpeNLcJ80NK65_fV7S1URWK2DJDpV27WG7FD5VZsfFVodF6E_6KI51EW1dNf095hdyjf10zfCEVHp52s13p8g/pastel-path.jpg?format=1000w"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://images.squarespace-cdn.com/content/v1/5755d322b654f9b7a7138680/1512578137483-KVSY3CDDSCBMB43YWGML/ke17ZwdGBToddI8pDm48kMsthtjYkJ8nfNfBufl--ll7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QPOohDIaIeljMHgDF5CVlOqpeNLcJ80NK65_fV7S1URCrmVTcbVcueuPS3h-PyB8ExDIBEJhs0dqkXfC2OV8Th5KyOUg5dP663hSigjxalg/agon-wastes.jpg?format=1000w"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://images.squarespace-cdn.com/content/v1/5755d322b654f9b7a7138680/1552370858565-W4G3JX9XLZL2KDCXGSNT/ke17ZwdGBToddI8pDm48kB6N0s8PWtX2k_eW8krg04V7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QPOohDIaIeljMHgDF5CVlOqpeNLcJ80NK65_fV7S1URWK2DJDpV27WG7FD5VZsfFVodF6E_6KI51EW1dNf095hdyjf10zfCEVHp52s13p8g/hibernation-ii.jpg?format=1000w"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://images.squarespace-cdn.com/content/v1/5755d322b654f9b7a7138680/1512575813006-E2HOSNCF9LS7FIUA2VSK/ke17ZwdGBToddI8pDm48kPAZtedKcstQn5lkxcvJ9Pl7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QPOohDIaIeljMHgDF5CVlOqpeNLcJ80NK65_fV7S1UdNeDXidC96Af-Cf6sp1jMrCzqiMENU4jUTELBggLms7oBSMXAnldsfOA25pB9z1bg/sanctuary.jpg?format=1000w"
  })), "\n", React.createElement(_components.p, null, "In his ", React.createElement(L, {
    h: "https://www.alexnoriega.com/tutorials",
    t: "video lessons"
  }), " he focuses on a thoughtful approach to image processing, always keeping in mind the end goal. You will learn how to get everything out of the RAW first and how then tailor results in Photoshop with the use of luminosity masks and color dodge&burn techniques, getting a balanced and naturally processed look."), "\n", React.createElement(_components.h3, {
    id: "nick-page",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#nick-page",
    "aria-label": "nick page permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Nick Page"), "\n", React.createElement(_components.p, null, "Nick is well known in the community thanks to his great ", React.createElement(L, {
    t: "YouTube Channel",
    h: "https://www.youtube.com/channel/UCxv1rK6prSp2aoNqNyxD_Vg"
  }), " and ", React.createElement(L, {
    t: "The Landscape Photography podcast",
    h: "https://the-landscape-photography.blubrry.net"
  }), "."), "\n", React.createElement(_components.p, null, "His image ", React.createElement(L, {
    t: "portfolio",
    h: "https://www.nickpagephotography.com/landscapes"
  }), " is very diverse, having both small scenes and grand vistas. Here are a few of his images."), "\n", React.createElement(Gallery, gallerySettings, React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://images.squarespace-cdn.com/content/v1/527bf8ede4b0d1e6b3978c25/1571762048808-1PBDEWU195HL5HSCH404/ke17ZwdGBToddI8pDm48kKiD9q-Va_LAv-utEyrxdVJ7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QPOohDIaIeljMHgDF5CVlOqpeNLcJ80NK65_fV7S1UXbuO9Gjtq6AR21vIT_JXydOApAL7zJGZzokmiDzFMvYjgXmDQ2guelmciNwIXo8Rg/light-through-the-forest.jpg?format=1000w"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://images.squarespace-cdn.com/content/v1/527bf8ede4b0d1e6b3978c25/1563828135952-7VW0HD07T2RSW4J5WT43/ke17ZwdGBToddI8pDm48kApm4bZ6Mn3TiUWou3kA3bp7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QPOohDIaIeljMHgDF5CVlOqpeNLcJ80NK65_fV7S1UWZaykogqWzieOupsqeSuBYug-y5Ut2InYZT1FrJD3yL-rj95DA1mjJIBxXFGHkuyQ/dune-glow.jpg?format=1000w"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://images.squarespace-cdn.com/content/v1/527bf8ede4b0d1e6b3978c25/1541004230180-311JFOK5HKMBNEGHQULB/ke17ZwdGBToddI8pDm48kMByRItrg22pYD1JpQi_B3Z7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QPOohDIaIeljMHgDF5CVlOqpeNLcJ80NK65_fV7S1UexfDnUFGC9HM_bKTEAF_GUKniVlrQCVgi7ftSO58TgTEaMRF0sI_PjrbfvnLkZ8ZQ/mystical-forest.jpg?format=1000w"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://images.squarespace-cdn.com/content/v1/527bf8ede4b0d1e6b3978c25/1480020021196-FIDBWUAD6SIJJRXEC3UV/ke17ZwdGBToddI8pDm48kF3x4cMLywJA6qddEoR2ap17gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QPOohDIaIeljMHgDF5CVlOqpeNLcJ80NK65_fV7S1Uche_rRz4dlaJ6jtqRd2NXB0-CQA1xC_uyyVFNdEMhCxl2zTAtlDkkoFadVcrg4iSg/HM5A3793-Edit+-+Copy.jpg?format=1000w"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://images.squarespace-cdn.com/content/v1/527bf8ede4b0d1e6b3978c25/1480020032775-C2IS28F1GVXUVUU510TN/ke17ZwdGBToddI8pDm48kJ0qZ6q-L5iEovM5tongiVF7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QPOohDIaIeljMHgDF5CVlOqpeNLcJ80NK65_fV7S1UXPBQkCIeF3dpnCqbkgF0S_YEKQzy_kj9ui6a46lW5_K1LPB70pGRsDT9qmfFRJw7Q/HM5A0999-Edit.jpg?format=1000w"
  })), "\n", React.createElement(_components.p, null, "Nick's ", React.createElement(L, {
    t: "tutorials",
    h: "https://www.nickpagephotography.com/landscapes"
  }), " are usually dedicated to a particular topic, like mastering dodging and burning or luminosity masks. Be sure to check full list of his lessons."), "\n", React.createElement(_components.h3, {
    id: "daniel-kordan",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#daniel-kordan",
    "aria-label": "daniel kordan permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Daniel Kordan"), "\n", React.createElement(_components.p, null, "Daniel became very popular a few years ago thanks to his grand landscapes with a very distinctive look. Having graduted art painting school, he was able to apply his experience in landscapes too."), "\n", React.createElement(_components.p, null, "Here are a few examples of Daniel's works. You can find full gallery on his ", React.createElement(L, {
    t: "website",
    h: "http://danielkordan.com"
  }), "."), "\n", React.createElement(Gallery, gallerySettings, React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "http://danielkordan.com/wp-content/uploads/2013/12/563396-1030x656.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "http://danielkordan.com/wp-content/uploads/2013/12/%D0%97%D0%B8%D0%BC%D0%BD%D1%8F%D1%8F-%D0%98%D1%81%D0%BB%D0%B0%D0%BD%D0%B4%D0%B8%D1%8F-14-1030x700.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "http://danielkordan.com/wp-content/uploads/2013/12/%D0%A2%D0%BE%D1%81%D0%BA%D0%B0%D0%BD%D0%B0-2012-2-1030x687.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "http://danielkordan.com/wp-content/uploads/2018/01/DSC_4216-3jj-1030x687.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "http://danielkordan.com/wp-content/uploads/2018/01/DSC_1402%D0%BE%D0%BE-1030x687.jpg"
  })), "\n", React.createElement(_components.p, null, "Being well versed in all kinds of landscape photography, doing lots of panoramas and night shots, Daniel is also very experienced in color dodging and burning."), "\n", React.createElement(_components.p, null, "If you would like to know how to get this painterly feel in your images, be sure to check ", React.createElement(L, {
    t: "his tutorials",
    h: "https://www.danielkordantutorials.com/"
  }), "."), "\n", React.createElement(_components.h3, {
    id: "sean-bagshaw",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#sean-bagshaw",
    "aria-label": "sean bagshaw permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Sean Bagshaw"), "\n", React.createElement(_components.p, null, "Sean Bagshaw is shooting landscapes for more than 15 years already. Being part of a well known ", React.createElement(L, {
    t: "Photocascadia",
    h: "https://www.photocascadia.com/"
  }), " team, over the years he assembled an amazing body of landscape work."), "\n", React.createElement(_components.p, null, "Here are a few of his images. You can browse though an extensive gallery on the ", React.createElement(L, {
    t: "website",
    h: "https://www.outdoorexposurephoto.com/product-category/signed-prints/"
  }), "."), "\n", React.createElement(Gallery, gallerySettings, React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://www.outdoorexposurephoto.com/wp-content/uploads/2018/08/Winter-Bones.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://www.outdoorexposurephoto.com/wp-content/uploads/2018/08/Timeless-Transition.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://www.outdoorexposurephoto.com/wp-content/uploads/2018/08/Hill-Top-Church.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://www.outdoorexposurephoto.com/wp-content/uploads/2018/06/guanajuato-illumination_1_1.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://www.outdoorexposurephoto.com/wp-content/uploads/2018/06/distant-planet.jpg"
  })), "\n", React.createElement(_components.p, null, "Sean is a well known expert on luminosity masks. It would not be underestimation to say that he, most likely, is the author behind most of the luminosity masking techniques everybody uses in post-processing nowadays."), "\n", React.createElement(_components.p, null, "While many videos are publsihed on his ", React.createElement(L, {
    t: "YouTube channel",
    h: "https://www.youtube.com/user/mo4nta1n"
  }), ", his ", React.createElement(L, {
    t: "advanced tutorials",
    h: "https://www.outdoorexposurephoto.com/video-tutorials/video-tutorials/"
  }), " are a must have if you want to learn various advanced techniques of post-processing with luminosity masks."), "\n", React.createElement(_components.h3, {
    id: "ryan-dyar",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#ryan-dyar",
    "aria-label": "ryan dyar permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Ryan Dyar"), "\n", React.createElement(_components.p, null, "Works of Ryan Dyar took landscape community by the storm several years ago. While many photographers were already well-versed in post-processing, Ryan's images really have pushed the boundaries."), "\n", React.createElement(_components.p, null, "He initially became famous thanks to his grand vista images with otherwordly light. Be sure to visit his amazing gallery on the ", React.createElement(L, {
    t: "website",
    h: "https://www.ryandyar.com/new-work"
  }), "."), "\n", React.createElement(Gallery, gallerySettings, React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://static.wixstatic.com/media/720132_98ac2326586b4640a992356f3fc87f2b~mv2_d_1800_1201_s_2.jpg/v1/fill/w_1640,h_1094,al_c,q_90,usm_0.33_1.00_0.00/720132_98ac2326586b4640a992356f3fc87f2b~mv2_d_1800_1201_s_2.webp"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://static.wixstatic.com/media/720132_f745768658974de39e9131c2899caefa~mv2.jpg/v1/fill/w_1100,h_818,al_c,q_90,usm_0.33_1.00_0.00/720132_f745768658974de39e9131c2899caefa~mv2.webp"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://static.wixstatic.com/media/720132_d86ac9095e9646e2a48b22e890a6a0f1~mv2_d_2000_1335_s_2.jpg/v1/fill/w_1613,h_1077,al_c,q_90,usm_0.33_1.00_0.00/720132_d86ac9095e9646e2a48b22e890a6a0f1~mv2_d_2000_1335_s_2.webp"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://static.wixstatic.com/media/720132_0ec52278bd45421e994c6d0ab02d2c27~mv2.jpg/v1/fill/w_1000,h_666,al_c,q_90,usm_0.33_1.00_0.00/720132_0ec52278bd45421e994c6d0ab02d2c27~mv2.webp"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://static.wixstatic.com/media/720132_eb948bf2759f40d08adc0fe207d1bbf3~mv2.jpg/v1/fill/w_1875,h_1094,al_c,q_90,usm_0.33_1.00_0.00/720132_eb948bf2759f40d08adc0fe207d1bbf3~mv2.webp"
  })), "\n", React.createElement(_components.p, null, "It is thought that Ryan was one of the first to introduce such post-processing techniques as color dodge&burn, focal blending and wide angle focus stacking. For sure, you will be able to learn about these and other techniques in his ", React.createElement(L, {
    t: "instructional videos",
    h: "https://www.ryandyar.com/instructional-videos"
  }), "."), "\n", React.createElement(_components.h3, {
    id: "chip-phillips",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#chip-phillips",
    "aria-label": "chip phillips permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Chip Phillips"), "\n", React.createElement(_components.p, null, "Chip is another member of a well known ", React.createElement(L, {
    t: "Photocascadia",
    h: "https://www.photocascadia.com/"
  }), " team.\nHis images a usually bright, with sunsets and sunrises bathing sceens in bright colors."), "\n", React.createElement(_components.p, null, "Here are some of the examples. You can find a full gallery on the ", React.createElement(L, {
    t: "website",
    h: "https://www.chipphillipsphotography.com/Landscapes"
  }), "."), "\n", React.createElement(Gallery, gallerySettings, React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://photos.smugmug.com/photos/i-Nd8FBJH/0/O/i-Nd8FBJH-O.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://photos.smugmug.com/photos/i-PtXfj9M/3/O/i-PtXfj9M-O.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://photos.smugmug.com/photos/i-CQM6BJs/0/O/i-CQM6BJs-O.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://photos.smugmug.com/photos/i-Zr6bhdv/0/O/i-Zr6bhdv-O.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://photos.smugmug.com/photos/i-Gd84pJ8/2/O/i-Gd84pJ8-O.jpg"
  })), "\n", React.createElement(_components.p, null, "Chip's ", React.createElement(L, {
    t: "tutoruals",
    h: "https://www.chipphillipsphotography.com/Other/Videos/n-j6rMms"
  }), " will suite greatly anyone who wants to learn everything right from the beginning, starting with workspace setup, layers and basic luminosity masks and ending with micro-contract, clarity, colorization and preparation for print."), "\n", React.createElement(_components.h3, {
    id: "enrico-fossati",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#enrico-fossati",
    "aria-label": "enrico fossati permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Enrico Fossati"), "\n", React.createElement(_components.p, null, "If you like mood in landscape images, then Enrico is someone you definitely need to check. His works quite suddenly became popular thanks to his careful and maybe even a bit dark post-processing style. While photography websites were full with bright and colorful sunsets, dark atmosphere of Enrico's images attracted many."), "\n", React.createElement(_components.p, null, "Here are some examples. Be sure to browse his full ", React.createElement(L, {
    t: "gallery",
    h: "https://www.enricofossati.it/recentworks"
  }), "."), "\n", React.createElement(Gallery, gallerySettings, React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://static.wixstatic.com/media/6ea9d7_d6e2037f09f1485dbd65cc5d5aa4ed32~mv2.jpg/v1/fill/w_1038,h_693,fp_0.50_0.50,q_90/6ea9d7_d6e2037f09f1485dbd65cc5d5aa4ed32~mv2.webp"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://static.wixstatic.com/media/6ea9d7_f4b1d42aa90e4ce58fe0907ab229213b~mv2_d_1920_1281_s_2.jpg/v1/fill/w_1039,h_693,fp_0.50_0.50,q_90/6ea9d7_f4b1d42aa90e4ce58fe0907ab229213b~mv2_d_1920_1281_s_2.webp"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://static.wixstatic.com/media/6ea9d7_e68fff5547b9485783cfc2c7ef202328~mv2.jpg/v1/fill/w_1038,h_693,fp_0.50_0.50,q_90/6ea9d7_e68fff5547b9485783cfc2c7ef202328~mv2.webp"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://static.wixstatic.com/media/6ea9d7_2389d98b3f714c068f86bb216b6323e4~mv2_d_1920_1280_s_2.jpg/v1/fill/w_1040,h_693,fp_0.50_0.50,q_90/6ea9d7_2389d98b3f714c068f86bb216b6323e4~mv2_d_1920_1280_s_2.webp"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://static.wixstatic.com/media/6ea9d7_81bc14f6d90b40a097a997c06ac77264~mv2.jpg/v1/fill/w_1038,h_693,fp_0.50_0.50,q_90/6ea9d7_81bc14f6d90b40a097a997c06ac77264~mv2.webp"
  })), "\n", React.createElement(_components.p, null, "While Enrico is still assembling list of his tutorials, you can already find few of them on the ", React.createElement(L, {
    t: "Mango-Ice Photography website",
    h: "https://mango-ice.photography/instructor/enrico-fossati/videos"
  }), ". With great experience in color grading and mood, this would be something you can expect to learn from his tutorials."), "\n", React.createElement(_components.h3, {
    id: "eric-bennett",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#eric-bennett",
    "aria-label": "eric bennett permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Eric Bennett"), "\n", React.createElement(_components.p, null, "Shooting landscapes for many years and having visited more than 30 countries, Eric believes that photography has the power to cause people to fall in love with the remarkable, natural beauty of our planet. Following his unique style, he always tries to find new angles on seen and unseen places. In Eric's portfolio you can find not only grand landscapes, but also many examples of greatly shot and processed small scenes and intimate landscapes."), "\n", React.createElement(_components.p, null, "Here are just a few examples. Here is the link to the full ", React.createElement(L, {
    t: "gallery",
    h: "https://www.bennettfilm.com/LatestReleases"
  }), "."), "\n", React.createElement(Gallery, gallerySettings, React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://photos.smugmug.com/photos/i-4qncGjR/2/O/i-4qncGjR-O.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://photos.smugmug.com/photos/i-VkNx6Z2/3/O/i-VkNx6Z2-O.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://photos.smugmug.com/photos/i-LFVnHZd/1/O/i-LFVnHZd-O.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://photos.smugmug.com/photos/i-N982QQs/12/O/i-N982QQs-O.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://photos.smugmug.com/photos/i-gKXdqKz/0/O/i-gKXdqKz-O.jpg"
  })), "\n", React.createElement(_components.p, null, "Eric has two different series of tutorial videos. First one shows entire workflow from  ", React.createElement(L, {
    t: "Start to Finish",
    h: "https://www.bennettfilm.com/StartToFinish"
  }), ", focusing on improving visual flow, enhancing light, and removing distractions, in order to make your images more powerful and concise."), "\n", React.createElement(_components.p, null, "The other series, ", React.createElement(L, {
    t: "Tools Of The Trade",
    h: "https://www.bennettfilm.com/ToolsOfTheTrade"
  }), ", focuses on 5 different techniques in each video, going in depth on each one and showing how it was used in a variety of different examples. This series was created in order to help photographers learn the exact techniques they are looking for and gain a deep understanding of them, so they know not only how to execute each technique, but mainly when and why they should use it."), "\n", React.createElement(_components.h2, {
    id: "happy-learning",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#happy-learning",
    "aria-label": "happy learning permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Happy learning"), "\n", React.createElement(_components.p, null, "I hope this list was helpful and you will find some great teachers to learn from."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
